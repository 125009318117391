<template>
    <Page
        title="Companies"
        icon="mdi-home"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
    >
        <v-data-table
            :headers="tableHeaders"
            :items="tableItems"
            :items-per-page="40"
            class="elevation-1"
            @click:row="clickRow"
        ></v-data-table>
    </Page>
</template>

<script>
import api from '@/api';
export default {
    name: 'CompanyList',
    data() {
        return {
            tableHeaders: [],
            tableItems: [],
            companies: {},
            loadingMessage: 'Retrieving results.',
            isShowLoading: false,
            errorMessage: '',
            isShowError: false,
        };
    },
    methods: {
        async getCompanies() {
            this.isShowLoading = true;
            const result = await api.get(this.$store, 'company');
            this.companies = [...result.data.companies];
            this.setTableData();
        },
        clickRow(row) {
            // const { abn, code, name } = row;
            this.$router.push(`/auth/admin/company/sync/${row.id}`);
        },

        setTableData() {
            console.log(this.companies);
            this.tableHeaders = [
                {
                    text: 'Company Code',
                    align: 'center',
                    sortable: true,
                    value: 'code',
                },
                {
                    text: 'Company Name',
                    align: 'left',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: 'Company ABN',
                    align: 'left',
                    sortable: true,
                    value: 'abn',
                },
                {
                    text: 'TE Id',
                    align: 'center',
                    sortable: true,
                    value: 'TECompanyId',
                },
                {
                    text: 'QB Id',
                    align: 'center',
                    sortable: true,
                    value: 'QBCompanyId',
                },
            ];

            this.tableItems = this.companies.map(company => ({
                id: company.id,
                code: company.companyCode,
                name: company.companyName,
                abn: company.abn,
                TECompanyId: company.TECompanyId,
                QBCompanyId: company.QBCompanyId,
            }));
            this.isShowLoading = false;
            // set the headers and column
        },
    },
    mounted() {
        this.getCompanies();
    },
};
</script>

<style scoped></style>
